@media (max-width: 599px) {
  .margin {
    margin-top: 15px;
  }
}

@media (max-width: 525px) {
  .break {
    flex-direction: column;
  }
}