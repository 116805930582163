table {
    border-collapse: collapse;
}

@media (max-width: 959px) {
    .no_margin_min_size {
        margin: 0!important;
        margin-bottom: 15px!important;
    }
    .field {
        margin-top: 10px;
    }
}
