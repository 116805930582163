.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.mt-1 {
  margin-top: 4px;
}

table {
  border-collapse: collapse;
}

@media (min-width: 1280px) {
  .big {
    text-align: end;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1050px) {
  .flex {
    display: block;
  }
}

.only-print {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }

  .only-print {
    display: block;
  }

  body {
    .teste {
      display: none !important;
    }
  }
}

@media (max-width: 600px) {
  .center {
    text-align: center;
  }
}

@media (max-width: 905px) {
  .align {
    text-align: center;
  }
}
