* {
    margin: 0;
    padding: 0;
}

body {
    form {
        width: 100%;
    }

    hr {
        width: 100%;
        height: 2px;
    }

    a {
        text-decoration: none;
        font-family: "Roboto", sans-serif;
        font-size: 10pt;
        color: #4588c4;
    }
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
    overflow: hidden;
}
