@media print {
  body {
    .testePrint {
      display: none !important;
    }
    .marginPrint {
    }
  }
}

.notifications {
  color: #000;
  padding: 4px 10px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 5px;

  &:hover {
    background-color: #d1d1d1;
    cursor: pointer;
    border-radius: 5px;
  }
}

.modalNot {
  color: #000;
  z-index: 99;
  position: absolute;
  background: #fefefe;
  top: 45px;
  right: 0;
  width: 450px;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 3px 3px -1px rgb(0 0 0 / 20%), 0px 3px 5px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  .linkExams {
    text-decoration: none;
    color: #000;
  }
}

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
}
