* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #f3f3f3;

    .inicio {
        &:hover {
            p,
            h1 {
                color: rgb(63, 63, 63);
                cursor: pointer;
            }
            .borderDiv {
                border: 1px solid rgb(63, 63, 63);
            }
        }
    }
}
